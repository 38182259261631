import React from 'react';
import { Resource } from './Resource';
import { ResourceSettings } from './ResourceSettings';
import { Link } from "react-router-dom";

export type TSubscription = Subscription | null;

interface PauseCollection {
    Behavior: string;
    ResumesAt: number;
}

interface Recurring {
    AggregateUsage: string;
    Interval: string;
    IntervalCount: number;
    UsageType: string;
}

interface ItemPrice {
    ID: string;
    Active: boolean;
    BillingScheme: string;
    Created: number;
    Currency: string;
    Product: string;
    Recurring: Recurring;
    TiersMode: string;
    Type: string;
    UnitAmount: number;
    UnitAmountDecimal: number;
}

interface SubscriptionItem {
    ID: string;
    Created: number;
    Price: ItemPrice;
    Quantity: number;
    Subscription: string;
    Sku: string;
    Status: string;
}

export class Subscription extends Resource {
    StripeID: string = '';
    AccountID: number = 0;
    TenantID: number = 0;
    Webhook: string = '';
    CancelAtPeriodEnd: boolean = false;
    //Reseller: ResellerEntity;
    ResellerID: number = 0;
    CurrentPeriodEnd: number = 0;
    CurrentPeriodStart: number = 0;
    Customer: string = '';
    DefaultPaymentMethod: string = '';
    Items: SubscriptionItem[] = [];
    //LatestInvoice        :InvoiceEntity;
    LatestInvoiceID: number = 0;
    LatestInvoiceStripe: string = '';
    PendingSetupIntent: string = '';
    Status: string = '';
    ProvisioningStatus: string = '';
    //BillingData: BillingData;
    CancelAt: number = 0;
    CanceledAt: number = 0;
    Created: number = 0;
    DefaultSource: string = '';
    Discount: string = '';
    EndedAt: number = 0;
    PauseCollection?: PauseCollection;
    Schedule: string = '';
    StartDate: number = 0;
    TrialEnd: number = 0;
    TrialStart: number = 0;
    CreatedAt: number = 0;
    UpdatedAt: number = 0;

    constructor(tenantId: string, prod?: any) {
        super('products', tenantId, prod?.ID ?? 0);
        this.SetBody(prod);
    }

    GetNames(): string[] {
        return ['product', 'products'];
    }

    GetResourceSettings(): ResourceSettings {
        return {
            checks: true,
            resource: {
                name: 'subscriptions',
                filter: '',
            },
            columns: [
                { text: '#', field: 'ID', sortable: true },
                {
                    text: 'Customer',
                    field: 'Account.Email',
                    sortable: true,
                    type: (row: any) =>{
                        if(!row?.Account) return null
                        return <Link to={`/accounts/${row.ID}`}>
                        <a
                          href="#"
                          className={`flex flex-row items-center h-10 px-3 rounded-lg`}
                        >
                            <span className="ml-4 text-blueGray-400 underline cursor-pointer">{row.Account.Email}</span>
                        </a>
                    </Link>} },
                { text: 'Sku', field: 'Sku', sortable: true, searchable: true },
                { text: 'Status', type:"status:subscription", field: 'Status', sortable: true, searchable: true },
                {
                    text: 'Last payment at',
                    field: 'CurrentPeriodStart',
                    type: 'shortdate',
                    sortable: true,
                },                {
                    text: 'Amount',
                    field: 'LatestInvoice.Subtotal',
                    type: 'currency',
                },
                {
                    text: 'Next payment at',
                    field: 'CurrentPeriodEnd',
                    type: 'shortdate',
                    sortable: true,
                },
                {
                    text: 'Created at',
                    field: 'CreatedAt',
                    type: 'datetime',
                    sortable: true,
                },
                { text: '', field: 'actions' },
            ],
            actions: [
                {
                    text: 'Edit',
                    action: 'edit',
                    color: 'text-green-600',
                    icon: 'fal fa-edit',
                },
                {
                    text: 'Delete',
                    action: 'delete',
                    color: 'text-red-600',
                    icon: 'fal fa-trash',
                },
            ],
            bulkActions: [
                {
                    text: 'Delete',
                    action: 'delete',
                    buttonClass: 'danger',
                },
            ],
        };
    }
}
