import React, {
    forwardRef,
    useEffect,
    useState,
    useImperativeHandle,
} from 'react';
import { DateTime } from 'luxon';
import ReactPaginate from 'react-paginate';
import { GKBilling } from 'gkshared';
import Flag from 'react-world-flags';

import './table.css';
import { SortUp, SortDown, SortedUp, SortedDown, LoadingIcon } from '../icons';
import {get} from 'dot-prop';

function camelCase2Underscore(s: string): string {
    return s
        .replace(/\.?([A-Z]+)/g, (x, y) => `_${y.toLowerCase()}`)
        .replace(/^_/, '');
}

const HeaderCell = ({
    children = null,
    checks,
    column,
    sort,
    onChangeSort,
    loading,
    isChecked,
    onCheck,
}: any) => {
    let sorted = 0;
    if (column.field === sort.field) {
        sorted = sort.ascending ? 1 : -1;
    }

    return (
        <th
            className={`p-3 text-sm uppercase text-gray-800 border-b-2 border-gray-800 hidden lg:table-cell`}
        >
            <div className={'flex items-center justify-center'}>
                {column.field === 'ID' && checks && (
                    <div
                        className={`flex flex-1 ${loading ? 'opacity-0' : ''}`}
                    >
                        <input
                            className={'cursor-pointer form-checkbox mr-4'}
                            type="checkbox"
                            id="cbox2"
                            checked={isChecked}
                            style={{ width: 18, height: 18 }}
                            onChange={onCheck}
                        />
                    </div>
                )}
                <div className={'flex flex-1 items-center justify-center'}>
                    {children}
                    {column.sortable && (
                        <>
                            <div
                                className={'ml-2 mr-1 cursor-pointer'}
                                onClick={() => {
                                    if (sorted !== 1)
                                        onChangeSort({
                                            field: column.field,
                                            ascending: true,
                                        });
                                }}
                            >
                                {sorted === 1 ? SortedUp : SortUp}
                            </div>
                            <div
                                className={'cursor-pointer'}
                                onClick={() => {
                                    if (sorted !== -1)
                                        onChangeSort({
                                            field: column.field,
                                            ascending: false,
                                        });
                                }}
                            >
                                {sorted === -1 ? SortedDown : SortDown}{' '}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </th>
    );
};

const renderValue = (
    value: any,
    column: any,
    row: any,
    textStyle: any,
    renderText: any
) => {
    if(typeof column.type === 'function') {
        return <div>{column.type(row)}</div>
    }


    if (column.type === 'date') {
        const dt = DateTime.fromISO(value);
        return dt.isValid ? (
            <div className={textStyle}>
                {dt.toLocaleString(
                    Object.assign(DateTime.DATE_FULL, { locale: 'es-ES' })
                )}
            </div>
        ) : (
            <div className={textStyle}>---</div>
        );
    }
    if (column.type === 'shortdate') {
        const dt = DateTime.fromISO(value);
        return dt.isValid ? (
          <div className={textStyle}>
              {dt.toLocaleString(
                Object.assign(DateTime.DATE_SHORT, { locale: 'es-ES' })
              )}
          </div>
        ) : (
          <div className={textStyle}>---</div>
        );
    }
    if (column.type === 'datetime') {
        const dt = DateTime.fromISO(value);
        return dt.isValid ? (
            <div className={textStyle}>
                {dt.toLocaleString(
                    Object.assign(DateTime.DATETIME_SHORT_WITH_SECONDS, {
                        locale: 'es-ES',
                        hour: '2-digit',
                        minute: '2-digit',
                    })
                )}
            </div>
        ) : (
            <div className={textStyle}>---</div>
        );
    }
    if (column.type?.startsWith('currency')) {
        return (
            <div className={`${textStyle} text-right`}>
                {Number(value / 100).toLocaleString('es-ES', {
                    currency: 'EUR',
                    style: 'currency',
                    minimumFractionDigits: 2,
                })}
            </div>
        );
    }
    if (column.type === 'image') {
        return (
            <div className="">
                <img src={value} style={{ width: '40px' }} />
            </div>
        );
    }

    if (column.type === 'localizedString') {
        return (
            <div className={`${textStyle} w-full text-left`}>
                <div className="flex">
                    <Flag code="es" width="20px" className={'mr-2'} />{' '}
                    {value.es}
                </div>
                <div className="flex">
                    <Flag code="gb" width="20px" className={'mr-2'} />
                    {value.en}
                </div>
            </div>
        );
    }

    if (column.type === 'check') {
        return (
            <div className={`${textStyle} w-full text-center`}>
                <input type="checkbox" checked={value} />
            </div>
        );
    }

    if (column.type === 'status:subscription') {
        switch(value) {
            case "active": return <div className="px-2 bg-green-200 rounded">Active</div>
            case "incomplete": return <div className="px-2 bg-yellow-200 rounded">Incomplete</div>
            case "trialing": return <div className="px-2 bg-blue-200 rounded">Trialing</div>
            case "past_due": return <div className="px-2 bg-red-200 rounded">Expired</div>
        }
    }

   let v = value;
    if (renderText) {
        v = renderText(value, column, row);
    }

    return <div className={`${textStyle}`}>{v}</div>;
};

const TableRow = ({
    row,
    data,
    onAction = null,
    renderAction = null,
    renderText = null,
    onCheck,
    loadData,
}: any) => {
    if (row.dummy) {
        return (
            <tr className="lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0" />
        );
    }
    return (
        <tr className="text-sm lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            {data.columns.map((c: any, i: any) => {
                if (c.hidden) return null;

                if (c.field !== 'actions') {
                    //const value = row[c.field] ?? '';
                    const value = get(row, c.field) ?? '';
                    let textStyle = '';
                    if (c.getTextStyle) {
                        textStyle = c.getTextStyle(value);
                    }

                    return (
                        <td
                            key={`item-${i}`}
                            className="w-full lg:w-auto p-2 text-gray-800 text-center border-b block lg:table-cell relative lg:static"
                            style={{ width: c.field === 'ID' ? 180 : 'auto' }}
                        >
                            <div className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                {renderValue(
                                    value,
                                    c,
                                    row,
                                    textStyle,
                                    renderText
                                )}
                            </div>
                            <div className="flex justify-center">
                                {c.field === 'ID' && data.checks && (
                                    <div className={'flex px-1 flex-grow-0'}>
                                        <input
                                            className={
                                                'cursor-pointer form-checkbox mr-4'
                                            }
                                            type="checkbox"
                                            id="cbox2"
                                            checked={row.checked}
                                            style={{ width: 18, height: 18 }}
                                            onClick={() => {
                                                onCheck(row);
                                            }}
                                        />
                                    </div>
                                )}
                                <div className={'flex flex-1 justify-center'}>
                                    {renderValue(
                                        value,
                                        c,
                                        row,
                                        textStyle,
                                        renderText
                                    )}
                                </div>
                            </div>
                        </td>
                    );
                } else {
                    return (
                        <td
                            key={`item-${i}`}
                            className="w-full lg:w-auto p-2 text-gray-800  border-b block lg:table-cell relative lg:static"
                            style={{ width: 50 * data.actions.length }}
                        >
                            {data.actions.map((a: any, j: any) => {
                                if (renderAction) {
                                    if (renderAction(a.action, row) === false) {
                                        return null;
                                    }
                                }

                                if (a.icon) {
                                    return (
                                        <span className="tooltip">
                                            <i
                                                key={`action-${j}`}
                                                className={`${a.icon} ${
                                                    a.color ?? ''
                                                } text-lg mx-2 cursor-pointer hover:opacity-50`}
                                                onClick={() => {
                                                    if (onAction) {
                                                        onAction(a.action, [
                                                            row,
                                                        ]);
                                                    }
                                                }}
                                            />
                                            <span className="tooltiptext">
                                                {a.text}
                                            </span>
                                        </span>
                                    );
                                }

                                return (
                                    <span
                                        key={`action-${j}`}
                                        className={`${
                                            a.color ?? ''
                                        } hover:underline mx-2 cursor-pointer hover:opacity-50`}
                                        onClick={async () => {
                                            if (onAction) {
                                                onAction(a.action, [row]);
                                                await loadData();
                                            }
                                        }}
                                    >
                                        {a.text}
                                    </span>
                                );
                            })}
                        </td>
                    );
                }
            })}
        </tr>
    );
};

const initialSort = {
    field: 'ID',
    ascending: false,
};

export const Table = forwardRef((props: any, ref) => {
    const {
        settings,
        onAction = null,
        renderAction = null,
        renderText = null,
        emptyText = 'common.no-data',
        tenant = '',
    } = props;
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any>(null);
    const [totalPages, setTotalPages] = useState(0);
    const [currentSort, setCurrentSort] = useState(initialSort);
    const [currentSearch, setCurrentSearch] = useState('');

    useEffect(()=>{
        setCurrentSearch("");
        setCurrentPage(0);
        setPageSize(10);
        setData(null)
        setTotalPages(0)
        setCurrentSort(initialSort)
        loadData()
    }, [settings])

    useImperativeHandle(ref, () => ({
        async reload() {
            await loadData();
        },
    }));

    const loadData = async () => {
        setLoading(true);
        let searchFilter = '';
        if (currentSearch !== '') {
            const searchFields = settings.columns
                .filter((c: any) => c.searchable)
                .map((c: any) => camelCase2Underscore(c.field))
                .join(',');

            searchFilter = `search=[${currentSearch}, ${searchFields}]`;
        }

        const response = await fetchData(
            pageSize,
            currentPage + 1,
            currentSort,
            searchFilter
        );
        if (response && response.data) {
            setData(response.data);
            setTotalPages(response.totalCount);
        }
        setLoading(false);
    };

    useEffect(() => {
        loadData();
    }, [currentPage, pageSize, currentSort]);

    const handleOnPageChange = (currentPage: any) => {
        setCurrentPage(currentPage.selected);
    };

    const handleChangeSort = (s: any) => {
        setCurrentSort(s);
    };

    const handleOnCheck = (row: any) => {
        const nd = data.map((r: any) =>
            r.ID === row.ID ? { ...r, checked: !r.checked } : r
        );
        setData(nd);
    };

    const handleCheckAll = (e: any) => {
        const nd = data.map((r: any) => ({
            ...r,
            checked: e.currentTarget.checked,
        }));
        setData(nd);
    };

    const handleSearch = async (e: any) => {
        e.preventDefault();

        await loadData();
    };

    const fetchData = async (
        pageSize: number,
        page: number,
        sort: any,
        search: string
    ) => {
        setLoading(true);
        const gkb = new GKBilling(
            tenant !== '' ? tenant : process.env.REACT_APP_APPLICATION_ID || '', '/', true
        );
        const sortString = `${sort.field}.${sort.ascending ? 'ASC' : 'DESC'}`;

        const response: any = await gkb.ListApiResource(settings.resource, {
            pageSize,
            page,
            sort: sortString,
            search,
        });
        setLoading(false);
        return {
            data: response?.Data?.map((s: any) => ({
                ...s,
                checked: false,
                status:
                    s.status === 'active' && s.cancel_at_period_end
                        ? 'pending-cancel'
                        : s.status,
                current_period_end:
                    s.status === 'active' && !s.cancel_at_period_end
                        ? s.current_period_end
                        : '',
                total: s.fk_subscription_latest_invoice?.total ?? 0,
            })),
            totalCount: response?.TotalPages,
        };
    };

    const bulkActionsEnabled = true; //data?.filter((d: any) => d.checked).length > 0;

    return (
        <>
            <div style={{ minHeight: 700 }}>
                <div className={' w-full p-4 flex justify-between'}>
                    <div className={'flex items-center'}>
                        <span
                            className={`${
                                loading ? 'animate-spin' : ''
                            } cursor-pointer hover:opacity-50`}
                            onClick={loadData}
                        >
                            {LoadingIcon}
                        </span>

                        <span className={'ml-4'}>Bulk actions</span>
                        {settings.bulkActions.map((ba: any) => {
                            return (
                                <button
                                    className={`ml-2 ${ba.buttonClass} ${
                                        bulkActionsEnabled ? '' : 'disabled'
                                    }`}
                                    onClick={async () => {
                                        if (bulkActionsEnabled) {
                                            setLoading(true);
                                            await onAction(
                                                ba.action,
                                                data?.filter(
                                                    (d: any) => d.checked
                                                )
                                            );
                                            await loadData();
                                        }
                                    }}
                                >
                                    {ba.text}
                                </button>
                            );
                        })}
                    </div>
                    <div className="flex items-center">
                        <input
                            id="grid-name"
                            type="text"
                            className={`px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear  `}
                            placeholder={'Text to search...'}
                            onChange={(e) => {
                                setCurrentSearch(e.currentTarget.value);
                            }}
                            onKeyDown={async (e) => {
                                if (e.key === 'Enter') {
                                    await handleSearch(e);
                                }
                            }}
                            style={{ width: 300 }}
                        />
                        <button
                            className="primary ml-2 shadow-xl"
                            onClick={handleSearch}
                        >
                            Search
                        </button>
                    </div>
                </div>
                <div className={`rounded ${loading ? 'opacity-20' : ''}`}>
                    <table className={`gktable border-0 w-full`}>
                        <thead className="bg-transparent">
                            <tr>
                                {settings.columns.map((c: any, i: any) =>
                                    c.hidden ? null : (
                                        <HeaderCell
                                            checks={settings.checks}
                                            key={`col-${i}`}
                                            column={c}
                                            sort={currentSort}
                                            loading={loading}
                                            isChecked={
                                                data
                                                    ? data.filter(
                                                          (d: any) => d.checked
                                                      ).length ===
                                                          data.length &&
                                                      data.length > 0
                                                    : false
                                            }
                                            onChangeSort={handleChangeSort}
                                            onCheck={handleCheckAll}
                                        >
                                            {c.text}
                                        </HeaderCell>
                                    )
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {data &&
                                data.map((r: any, j: any) => (
                                    <TableRow
                                        key={`row-${j}`}
                                        onAction={onAction}
                                        row={r}
                                        data={settings}
                                        renderAction={renderAction}
                                        renderText={renderText}
                                        onCheck={handleOnCheck}
                                        loadData={loadData}
                                    />
                                ))}
                        </tbody>
                    </table>
                    {data?.length === 0 && (
                        <div className="font-semibold w-full text-center py-10 text-2xl">
                            {emptyText}
                        </div>
                    )}

                    {totalPages > 1 && (
                        <ReactPaginate
                            initialPage={0}
                            disableInitialCallback={true}
                            pageCount={totalPages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            onPageChange={handleOnPageChange}
                            previousLabel={'previous'}
                            nextLabel={'next'}
                            containerClassName={'paginator'}
                            pageLinkClassName={'page'}
                            activeLinkClassName={'active'}
                            previousLinkClassName={'page'}
                            nextLinkClassName={'page'}
                            disabledClassName={'disabled'}
                            breakLinkClassName={'breakLink'}
                        />
                    )}
                </div>
            </div>
        </>
    );
});
