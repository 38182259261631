import React from 'react';
import { Resource } from './Resource';
import { ResourceSettings } from './ResourceSettings';

export type TAccount = Account | null;


export class Account extends Resource {
    public Email: string;
    public FirstName: string;
    public LastName: string;
    private FullName: string
    public ImageUrl: string
    public Active: boolean;
    public Banned: boolean;
    public VerifiedAt: boolean;

    constructor(tenantId: string, acc?: any) {
        super('accounts', tenantId, acc?.ID ?? 0);
        this.SetBody(acc);
    }

    GetNames(): string[] {
        return ['account', 'accounts'];
    }

    GetResourceSettings(): ResourceSettings {
        return {
            checks: true,
            resource: {
                name: 'accounts',
                filter: '',
            },
            columns: [
                // { text: '#', field: 'ID', sortable: true },
                // { text: 'Avatar', field: 'ImageUrl', type: 'image', },
                { text: 'Email', field: 'Email', sortable: true },
                { text: 'Name', field: 'FirstName', sortable: true, type: (row: any) : any =>{ return <div>{`${row.FirstName} ${row.LastName}`}</div>} },
                { text: 'Active', field: 'Active', sortable: true, type: "check" },
                { text: 'Verified', field: 'VerifiedAt', sortable: true, type: "check" },
                { text: 'Banned', field: 'Banned', sortable: true, type: "check" },
                { text: '', field: 'actions' },
            ],
            actions: [
                {
                    text: 'Edit',
                    action: 'edit',
                    color: 'text-green-600',
                    icon: 'fal fa-edit',
                },
            ],
            bulkActions: [
                {
                    text: 'Add customer',
                    action: 'new',
                    buttonClass: 'primary',
                },
            ],
            editor: {
                isValid: (resource: Resource | null) => {
                    return resource?.Get('Content') !== '';
                },
                sections: [
                    {
                        title: 'General',
                        collapsable: false,
                        defaultCollapsed: false,
                        fields: [
                            {
                                field: 'Email',
                                type: 'input',
                                data: 'email',
                                width: 2,
                            },
                            {
                                field: 'FirstName',
                                type: 'input',
                                width: 2,
                            },
                            {
                                field: 'LastName',
                                type: 'input',
                                width: 2,
                            },
                            {
                                field: 'Active',
                                type: 'checkbox',
                                width: 2
                            },
                            {
                                field: 'VerifiedAt',
                                type: 'checkbox',
                                width: 2,
                            },
                            {
                                field: 'Banned',
                                type: 'checkbox',
                                width: 2
                            },
                            {
                                field: 'Password',
                                type: 'input',
                                width: 6,
                            },
                        ],
                    },
                ],
            },
        };
    }
}
